// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clzsizAYfQ4KM_V8rCx6 {
  height: calc(100vh - 80px);
  min-width: 1000px;
  padding: 0 10px;
  background-color: #f0f2f5;
}
`, "",{"version":3,"sources":["webpack://./src/layout/index.module.less"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,iBAAA;EACA,eAAA;EACA,yBAAA;AACJ","sourcesContent":[".content {\n    height: calc(100vh - 80px);\n    min-width: 1000px;\n    padding: 0 10px;\n    background-color: #f0f2f5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `clzsizAYfQ4KM_V8rCx6`
};
export default ___CSS_LOADER_EXPORT___;
