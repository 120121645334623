// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  height: 300px;
  width: 100%;
}
.login-form .ant-input-affix-wrapper {
  border: 1px solid #ccc;
  background-color: rgba(0, 0, 0, 0.04);
  height: 56px;
  line-height: 56px;
  border-radius: 4px;
}
.login-form .ant-input {
  background-color: transparent;
}
.login-form .login_form_btn {
  width: 50%;
  border-radius: 4px;
  height: 56px;
  line-height: 50px;
  margin-top: 20px;
  background-color: #98D5B9;
  border-color: #98D5B9;
  font-size: 24px;
}
.login-form .login_form_btn:hover {
  color: #fff;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), #98D5B9;
}
.login-form .login_username_login_btn {
  display: flex;
  justify-self: flex-start;
}
.login-form .count-down {
  width: 100px;
  height: 56px;
  line-height: 56px;
  background-color: #98D5B9;
  border-radius: 4px;
  color: #fff;
}
.login-form .login-form-item-code .ant-input-suffix {
  margin-right: -12px;
}
.count-down .count-down-send {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/login/index.less"],"names":[],"mappings":"AAEA;EACG,aAAA;EACA,WAAA;AADH;AADA;EAKM,sBAAA;EACA,qCAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AADN;AARA;EAaM,6BAAA;AAFN;AAXA;EAiBM,UAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,qBAAA;EACA,eAAA;AAHN;AArBA;EA4BM,WAAA;EACA,gGAAA;AAJN;AAzBA;EAgCM,aAAA;EACA,wBAAA;AAJN;AA7BA;EAqCM,YAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,WAAA;AALN;AArCA;EA+CS,mBAAA;AAPT;AAaA;EAEM,eAAA;AAZN","sourcesContent":["@import '../../less/color.less';\n\n.login-form {\n   height: 300px;\n   width: 100%;\n\n   .ant-input-affix-wrapper {\n      border: 1px solid #ccc;\n      background-color: rgba(0, 0, 0, 0.04);\n      height: 56px;\n      line-height: 56px;\n      border-radius: 4px;\n   }\n\n   .ant-input {\n      background-color: transparent;\n   }\n\n   .login_form_btn {\n      width: 50%;\n      border-radius: 4px;\n      height: 56px;\n      line-height: 50px;\n      margin-top: 20px;\n      background-color: @btnBgColor;\n      border-color: @btnBgColor;\n      font-size: 24px;\n   }\n\n   .login_form_btn:hover {\n      color: #fff;\n      background: linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), @btnBgColor\n   }\n   .login_username_login_btn{\n      display: flex;\n      justify-self: flex-start;\n   }\n\n   .count-down {\n      width: 100px;\n      height: 56px;\n      line-height: 56px;\n      background-color: @btnBgColor;\n      border-radius: 4px;\n      color: #fff;\n   }\n\n   .login-form-item-code {\n      .ant-input-suffix {\n         margin-right: -12px;\n      }\n   }\n\n}\n\n.count-down {\n   .count-down-send {\n      cursor: pointer;\n   }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
