// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider_image .slick-initialized {
  width: 300px;
}
.slider_image .slick-prev:before,
.slider_image .slick-next:before {
  color: #999;
}
.slider_image .slick-prev,
.slider_image .slick-next {
  top: 38%;
}
.slider_image .slider_name {
  color: #333333;
  font-size: 24px;
  font-weight: bold;
  margin-top: 32px;
}
.slider_image .slick-dots li {
  margin: 0;
  width: 44px;
  margin: 0 10px;
}
.slider_image .slick-dots li button:before {
  opacity: 0.75;
  width: 44px;
  height: 8px;
  background-color: #D8D8D8;
  content: "";
}
.slider_image .slick-dots li.slick-active button:before {
  background-color: #98D5B9;
}
.slider_image .slider_a {
  color: #98D5B9;
  text-decoration: none;
  line-height: 24px;
  font-size: 16px;
}
.slider_image .slider_image-img-out {
  position: relative;
}
.slider_image .slider_image-img-out img {
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/components/swiperImg/index.less"],"names":[],"mappings":"AAEA;EAEQ,YAAA;AAFR;AAAA;;EAOQ,WAAA;AAHR;AAJA;;EAYQ,QAAA;AAJR;AARA;EAgBQ,cAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AALR;AAdA;EAsBQ,SAAA;EACA,WAAA;EACA,cAAA;AALR;AAnBA;EA4BQ,aAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;AANR;AA1BA;EAoCQ,yBAAA;AAPR;AA7BA;EAuCS,cAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;AAPT;AAnCA;EA6CQ,kBAAA;AAPR;AAtCA;EA+CY,kBAAA;AANZ","sourcesContent":["@import url('../../less/color.less');\n\n.slider_image {\n    .slick-initialized {\n        width: 300px;\n    }\n\n    .slick-prev:before,\n    .slick-next:before {\n        color: @gray1;\n    }\n\n    .slick-prev,\n    .slick-next {\n        top: 38%\n    }\n\n    .slider_name {\n        color: @black1;\n        font-size: 24px;\n        font-weight: bold;\n        margin-top: 32px;\n    }\n    .slick-dots li{\n        margin: 0;\n        width: 44px;\n        margin: 0 10px;\n    }\n\n    .slick-dots li button:before{\n        opacity: 0.75;\n        width: 44px;\n        height: 8px;\n        background-color: @white2;\n        content:\"\"\n    }\n\n    .slick-dots li.slick-active button:before{\n        background-color: @btnBgColor;\n    }\n    .slider_a{\n         color: @textGreenColor;\n         text-decoration: none;\n         line-height: 24px;\n         font-size: 16px;      \n    }\n    .slider_image-img-out{\n        position: relative;\n        img{\n            position: absolute;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
