// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forget-pwd {
  margin-top: 20px;
  height: 50px;
  width: 100px;
  margin-left: 5px;
  vertical-align: bottom;
  position: relative;
}
.forget-pwd .forget-pwd-txt {
  position: absolute;
  bottom: 0;
  cursor: pointer;
}
.forget-pwd .forget-pwd-txt:hover {
  color: #83ebba;
}
`, "",{"version":3,"sources":["webpack://./src/components/login/forgetPwd/index.less"],"names":[],"mappings":"AACA;EACI,gBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;EACA,kBAAA;AAAJ;AANA;EAQQ,kBAAA;EACA,SAAA;EACA,eAAA;AACR;AAXA;EAaQ,cAAA;AACR","sourcesContent":["@import \"../../../less/color.less\";\n.forget-pwd{\n    margin-top: 20px;\n    height: 50px;\n    width: 100px;\n    margin-left: 5px;\n    vertical-align: bottom;\n    position: relative;\n    .forget-pwd-txt{\n        position: absolute;\n        bottom: 0;\n        cursor: pointer;\n    }\n    .forget-pwd-txt:hover{\n        color: @btnHovBgColor;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
